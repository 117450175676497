import React from "react";
import ImageComponent from "../../sharedComponent/ImageComponent";

function FlockerCard(props) {
  return (
    <>
      <div className="fsc-card">
        <div>{props.nft.title}</div>
        <div><ImageComponent url={props.nft.image} altText={props.nft.title} /></div>
        <div className="text-left">
          {props.nft.attributes.map(attribute => {
            return <div>{attribute.trait_type}: {attribute.value}</div>
          })}
          <div>Metadata: {props.nft.tokenUri}</div>
        </div>
      </div>
    </>
  );
}
export default FlockerCard;
