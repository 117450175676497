export function JoshuaDetail() {
  return (
    <div>
      <div className="team-detail-title">joshua hood “cyril keir”</div>
      <p className="team-detail-text">
        Over 15 years of experience starting and developing businesses into successful growing companies. When he was
        18, he started a wireless internet company in rural Virginia with a friend of his, bringing high-speed internet
        to an area that only had dial-up internet available at the time.{" "}
      </p>
      <p className="team-detail-text">
        For most of his working career, Joshua has been in charge of workflow organization, risk assessment, and
        ensuring that people were deployed in a manner that would ensure success for the companies he's owned and worked
        for. He's been involved in the crypto markets since 2014, and believes that the future of finance will move to
        digital currencies.
      </p>
    </div>
  );
}
export function EzekielDetail() {
  return (
    <>
      <div className="team-detail-title ">ezekiel racelis</div>
      <p className="team-detail-text">
        Over 9 yrs of project/program management for the US Air Force. Experience in running multiple projects and
        programs with assets worth $1B+. Other notable experiences; established operating location overseas, wrote
        operating policies and regulations.
      </p>
      <p className="team-detail-text">
        Participated in several tech startup vetting processes seeking US Air Force SBIR/development funding. "Mentor"
        volunteer experience with startup incubators/accelerators programs.
      </p>
    </>
  );
}

export function MatthewDetail() {
  return (
    <>
      <div className="team-detail-title ">matthew merrill</div>
      <p className="team-detail-text">
        15 years of experience working in technology spanning multiple roles including, but not limited to, developer,
        solution/enterprise architect, and DevOps. He is also certified in cloud architecture and security automation.
        Matt spent much of his career working in the retail sector for a midsize company focused mainly on the
        e-commerce website.
      </p>
      <p className="team-detail-text">
        Matt was also a contractor for an individually owned marketing company and spent some time in the education
        technology sector.
      </p>
    </>
  );
}

export function FranzDetail() {
  return (
    <>
      <div className="team-detail-title ">Franz escolastico</div>
      <p className="team-detail-text">
        9 years of experience in digital design, with a background that ranges from graphic designing, UI/UX, WordPress
        and Webflow development.
      </p>
      <p className="team-detail-text">
        In addition she's also an expert content creator for social media platforms like Instagram, TikTok, YouTube and
        Facebook who shares tips about the best ways being successful at life and career through creativity!
      </p>
    </>
  );
}

export function DennisDetail() {
  return (
    <>
      <div className="team-detail-title ">dennis perrin</div>
      <p className="team-detail-text">
        Experience with start-up ventures as a Director of Operations specializing in building system processes and
        predictive analytics.
      </p>
    </>
  );
}

export function ZhouDetail() {
  return (
    <>
      <div className="team-detail-title ">Zhou Fang</div>
      <p className="team-detail-text">
        Innovative Senior front-end web developer with 10+ years of strong technical skills in complex website
        development including single-page applications.
      </p>
    </>
  );
}

export function MindyDetail() {
  return (
    <>
      <div className="team-detail-title ">mindy mcclusky</div>
      <p className="team-detail-text">
        5 years as a 911 operator while acquiring a BA and an MA in the fields of Sociology and Criminology. She is now
        part-time as a 911 operator, doing full-time crypto, trading, and working at Parrotly Finance Inc.
      </p>
      <p className="team-detail-text">
        Her degrees of study give her unique experience with understanding how to study people, and find what drives and
        interests them, to appeal to their interests.{" "}
      </p>
    </>
  );
}
