import { useEffect, useState } from "react";
import { metaMaskService } from "../service/MetaMask.service";
import WalletSelector from "../sharedComponent/WalletSelector/WalletSelector";
import "./ConnectWalletButton.scss";

function ConnectWalletButton(props) {
  useEffect(() => {
    metaMaskService.init();
    return () => {
      metaMaskService.destroy();
    };
  }, []);

  const [show, setShow] = useState(false);

  async function showModal() {
    setShow(true);
  }

  function buttonClass() {
    return "hero-btn button large rounded outline purple" + (props.status === "connected" ? " hidden" : "");
  }

  return (
    <>
        <WalletSelector show={show} onClose={() => setShow(false)}></WalletSelector>
        <button
          type="button"
          id="connect-wallet"
          className={buttonClass()}
          onClick={showModal}
        >
          CONNECT WALLET
        </button>
    </>
  );
}
export default ConnectWalletButton;
