import { Link } from "react-router-dom";
import styles from "./logo.module.scss";
function Logo() {
  return (
    <div className={styles.logo}>
      <Link to="/">
        <img src="/asset/Parrotly-Finance.svg" alt="Parrotly logo" />
      </Link>
    </div>
  );
}
export default Logo;
