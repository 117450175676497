import { trigger } from "./event.service";
import WalletConnect from "@walletconnect/client";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";

class WalletConnectService {
  connector;
  addrArray;
  addressArray(data) {
    if (!data) {
      return this.addrArray;
    }
    this.addrArray = data;
  }


  async init() {
    this.connector = new WalletConnect(
      {
        bridge: "https://bridge.walletconnect.org",
      }
    );

    this.connector.on("connect", (error, payload) => {
      if (error) {
        this.triggerConnectFailed(error);
        return;
      }

      WalletConnectQRCodeModal.close(true);

      const { accounts } = payload.params[0];
      this.addressArray(accounts);
      this.triggerConnectSucceeded(this.addressArray());
    });

    this.connector.on("session_update", (error, payload) => {
      if (error) {
        this.triggerConnectFailed(error.message);
      }

      const { accounts } = payload.params[0];
      this.addressArray(accounts);
      this.triggerConnectSucceeded(this.addressArray());
    });

    this.connector.on("disconnect", (error) => {
      if (error) {
        this.triggerConnectFailed(error.message);
      }

      this.destroy();
    });
  }

  destroy() {
    this.triggerChainChanged();
  }

  async connect() {
    if(this.connector.connected) {
      this.addressArray(this.connector.accounts);
      this.triggerConnectSucceeded(this.addressArray());
    }
    else if (!this.connector.connected) {
      this.connector.createSession().then(() => {
        const uri = this.connector.uri;
        WalletConnectQRCodeModal.open(
          uri,
          () => {
            console.log("QR Code Modal closed");
          },
          true
        );
      });
    }
  }

  triggerConnectSucceeded(addressArray) {
    if (addressArray.length > 0) {
      trigger("connect", { status: "success", walletAddress: addressArray[0], walletProvider: this.connector });
    }
  }

  triggerConnectFailed(message) {
    trigger("connect", { status: "error", message: message });
  }

  triggerChainChanged() {
    window.location.reload();
  }
}
export const walletConnectService = new WalletConnectService();
