import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { fscParameters /*, icoParameters */ } from "../const";

class MintService {
  web3;

  constructor() {
    this.web3 = createAlchemyWeb3(fscParameters.alchemyId);
  }

  // async buyPbirb(matic) {
  //   const maticAsWei = this.web3.utils.toWei(matic.toString(), "ether");
  //   const weiAsHex = this.web3.utils.toHex(maticAsWei);

  //   const transactionParameters = {
  //     to: icoParameters.contractAddress,
  //     from: window.ethereum.selectedAddress,
  //     value: weiAsHex,
  //   };

  //   return await window.ethereum.request({
  //     method: "eth_sendTransaction",
  //     params: [transactionParameters],
  //   });
  // }

  async mint(requestedAmount, walletAddress, walletProvider) {
    window.contract = new this.web3.eth.Contract(fscParameters.contractAbi, fscParameters.contractAddress, {
      from: walletAddress,
    });
    const priceInMatic = (
      parseInt(this.web3.utils.toWei(fscParameters.priceInMatic, "ether")) * requestedAmount
    );
    const priceInMaticAsHex = this.web3.utils.toHex(priceInMatic.toString());
    const transactionParameters = {
      to: fscParameters.contractAddress,
      from: walletAddress,
      data: window.contract.methods.mint(requestedAmount).encodeABI(),
      value: priceInMaticAsHex
    };

    // MetaMask
    if (walletProvider === window.ethereum) {
      return await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
    }

    // WalletConnect
    console.log(priceInMatic);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gasAmount = await this.web3.eth.estimateGas({
      method: "eth_sendTransaction",
      params: [transactionParameters]
    });
    transactionParameters.gas = gasAmount;
    transactionParameters.gasPrice = gasPrice;
    return await walletProvider.sendTransaction(transactionParameters);
  }
}

export const mintService = new MintService();
