import { fscParameters } from "../const";
import { useEffect, useState } from "react";
import { mintService } from "../service/Mint.service";
import { on } from "../service/event.service";
import { off } from "process";
import ConnectWalletButton from "../sharedComponent/ConnectWalletButton";

function NftBuyBox() {
  useEffect(() => {
    on("connect", handleConnectWalletEvent);
    
    return () => {
      off("connect", handleConnectWalletEvent);
    };
  }, []);

  const [walletAddress, setWalletAddress] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [flockerCount, setFlockerCount] = useState("1");
  const [hash, setHash] = useState(null);
  const [walletProvider, setWalletProvider] = useState(null);

  const handleConnectWalletEvent = ({ detail }) => {
    const { status } = detail;
    if (status === "success") {
      setWalletProvider(detail.walletProvider);
      setWalletAddress(detail.walletAddress);
      setErrorMsg(undefined);
    } else {
      setWalletProvider(null);
      setWalletAddress(undefined);
      setErrorMsg(detail.message);
    }
  };

  async function onMint() {
    let requestedAmount = parseInt(flockerCount);
    if (isNaN(requestedAmount) || requestedAmount < 1 || requestedAmount > 5) {
      requestedAmount = 1;
    }

    try {
      const txHash = await mintService.mint(requestedAmount, walletAddress, walletProvider);
      setHash(txHash);
    } catch (err) {
      setErrorMsg(err.message);
    }
  }

  return (
    <>
      <ConnectWalletButton status={!walletAddress ? "disconnected" : "connected"}></ConnectWalletButton>
      {walletAddress && 
        <div id="mint">
          <select
            id="flocker-count"
            className="flocker-count rounded"
            value={flockerCount}
            onChange={(e) => {
              setFlockerCount(e.target.value);
            }}
          >
            <option value="1">1 Flocker</option>
            <option value="2">2 Flockers</option>
            <option value="3">3 Flockers</option>
            <option value="4">4 Flockers</option>
            <option value="5">5 Flockers</option>
          </select>
          <i className="arrow down"></i>

          <button type="button" className="hero-btn button rounded outline purple" onClick={onMint}>
            MINT
          </button>
        </div>
      }

      <p id="flocker-price">{fscParameters.priceInMatic} MATIC per Flocker</p>
      <p id="mint-message">
        {!!walletAddress && <span className="wallet-address">Connected with wallet: {walletAddress}</span>}
        <span className="errorMsg">{errorMsg}</span>
        {!!hash && (
          <span className="hash">
            Transaction Hash: <a href={`https://polygonscan.com/tx/${hash}`}>{hash}</a>
          </span>
        )}
      </p>
    </>
  );
}
export default NftBuyBox;
