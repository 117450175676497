/* import styles from "./IcoPage.module.scss"; */

// import { useEffect, useState } from "react";
// import { purchaseSingleton } from "../service/Purchase2.service";
// import { on } from "../service/event.service";
// import { off } from "process";
/* import { whiteList } from "./whitelist.const";
import { StageWarper } from "./SaleStageCards/saleStageCards"; */
// import BuyBoxWarper from "./BuyBoxWarper";
// import { mintSingleton } from "../service/Mint.service";

function IcoPage() {
  // useEffect(() => {
  //   on("mint", handleMintEvent);
  //   mintSingleton.init();
  //   return () => {
  //     mintSingleton.destroy();
  //     off("mint", handleMintEvent);
  //   };
  // }, []);

  // const [walletAddress, setWalletAddress] = useState();
  // const [purchaseServiceData, setPurchaseServiceData] = useState(null);
  // const [errorMsg, setErrorMsg] = useState();

  // const handleMintEvent = ({ detail }) => {
  //   const { status } = detail;
  //   if (status === "success") {
  //     setWalletAddress(detail.walletAddress);
  //     setErrorMsg(undefined);
  //     purchaseSingleton.initIcoContract();
  //     getPurchaseServiceData();
  //   } else {
  //     setWalletAddress(undefined);
  //     setErrorMsg(detail.message);
  //   }
  // };

  // async function getPurchaseServiceData() {
  //   try {
  //     const purchaseServiceData = await purchaseSingleton.getAllData();
  //     setPurchaseServiceData(purchaseServiceData);
  //   } catch (err) {
  //     setErrorMsg(err.message);
  //   }
  // }
  window.location.replace(
    "https://quickswap.exchange/#/swap?outputCurrency=0x60eec374a1ba3907e9bdd8a74ce368d041d89c79"
  );

  /*   const renderWhitelist = () => {
    return Object.entries(whiteList).map(([key, value]) => {
      return (
        <div key={key} className={`row small-collapse ${styles.whitelist_row}`}>
          <div className={`columns small-6 ${styles.title}`}>{key}</div>
          <div className={`columns small-6 ${styles.value}`}>{value}</div>
        </div>
      );
    });
  }; */

  return (
    <>
      {/*       <section className={`text-center ${styles.hero}`}>
        <h1 className={styles.title}>
          pBirb <span className="thin">Token Presale</span>{" "}
        </h1>
      </section>

      <section className={`row ${styles.infoContainer} large-flex-dir-row-reverse`}>
        <div className={`${styles.card_Container} columns small-12 large-6 `}>
          <div className={styles.cards}>
            <StageWarper />
          </div>
        </div>
        <div className={`${styles.card_Container} columns small-12 large-6 `}>
          <div className={styles.cards}>
            <h5 className="text-center">Presale Information</h5>
            {renderWhitelist()}
          </div>
        </div>
      </section> */}
      {/* <BuyBoxWarper walletAddress={walletAddress} purchaseServiceData={purchaseServiceData} errorMsg={errorMsg} /> */}
    </>
  );
}
export default IcoPage;
