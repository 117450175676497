import styles from "./PopUp.module.scss";
function PopUp({ PopUpDetail, onClose }) {
  return (
    <div className={`${styles.container} flex-container align-center-middle`}>
      <div className={`${styles.popup} flex-container align-center-middle`}>
        <button className="close-button" aria-label="Close alert" type="button" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className={styles.main}>{PopUpDetail}</div>
      </div>
    </div>
  );
}
export default PopUp;
