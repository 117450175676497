import { Link, useLocation } from "react-router-dom";
import { contactUsEmail, partnersUrl, pdfUrls, routePath, socialLinks } from "../../const";
import ImageComponent from "../../sharedComponent/ImageComponent";
import Logo from "../logo/logo";

function FooterComponent() {
  const socialList = ({ showList } = { showList: true }) => {
    const list = Object.entries(socialLinks).map(([key, value]) => {
      return (
        <li key={key}>
          <a href={value} target="_blank" rel="noreferrer">
            {key}
          </a>
        </li>
      );
    });

    const icons = Object.entries(socialLinks).map(([key, value]) => {
      const url = key;
      const imgUrl = `/asset/social-icon/${url}.png`;
      return (
        <a href={value} target="_blank" rel="noreferrer" key={key}>
          <ImageComponent key={key} className="social-icon" url={imgUrl} altText={value} />
        </a>
      );
    });

    return showList ? list : icons;
  };
  const location = useLocation();
  return (
    <>
      {location.pathname !== routePath.ico && (
        <section className="partners-containers text-center">
          <h2 className="title">Our Partners</h2>
          <div className="row align-center">
            <div className="columns small-12 medium-3 small-collapse">
              <a href={partnersUrl.kapricat} target="_blank" rel="noreferrer">
                <ImageComponent url="/asset/logo-kapricat.png" altText="kapricat" />
              </a>
            </div>
            <div className="columns small-12 medium-3 small-collapse">
              <a href={partnersUrl.room101} target="_blank" rel="noreferrer">
                <ImageComponent url="/asset/logo-room101.png" altText="room101" />
              </a>
            </div>
            <div className="columns small-12 medium-3 small-collapse">
              <a href={partnersUrl.forint} className="logo-forint" target="_blank" rel="noreferrer">
                <ImageComponent url="/asset/logo-forint.png" altText="forint finance" />
              </a>
            </div>
          </div>
        </section>
      )}

      <div className="site-footer">
        <hr className="footer-hr" />
        <div className="row align-justify small-collapse">
          <div className="columns small-12 large-2 text-center large-text-left">
            <Logo className="logo" />
          </div>
          <nav className="small-12 xlarge-10 flex-container align-right align-top">
            <div className="menu-column align-center">
              <span className="category">Info</span>
              <ul className="no-bullet">
                <li>
                  <Link to={routePath.about}>About Us</Link>
                </li>
                <li>
                  <Link to={routePath.fsc}>Flockers Social Club</Link>
                </li>
                <li>
                  <a 
                    href="https://docs.parrotly.finance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Whitepaper</a>
                </li>
                <li>
                  <a 
                    href="https://quickswap.exchange/#/swap?outputCurrency=0x60eec374a1ba3907e9bdd8a74ce368d041d89c79"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Buy on Quickswap</a>                  
                </li>
              </ul>
            </div>
            <div className="menu-column align-center show-for-medium">
              <span className="category">Community</span>
              <ul className="no-bullet">{socialList({ showList: true })}</ul>
            </div>
            <div className="menu-column align-center">
              <span className="category">Legal</span>
              <ul className="no-bullet">
                <li>
                  <a href={pdfUrls["Terms of Use"]}>Terms of Use</a>
                </li>
                <li>
                  <a href={pdfUrls["Privacy and Policy"]}>Privacy/Cookie Policy</a>
                </li>
                <li>
                  <a href={pdfUrls.Disclaimer}>Disclaimer</a>
                </li>
                <li>
                  <a href={`mailto:${contactUsEmail}`}>Contact Us</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="copy-container row small-collapse align-justify align-middle">
          <div className="columns small-12 medium-6">&copy; 2022 Parrotly | All Rights Reserved.</div>
          <div className="columns small-12 medium-6 text-right">{socialList({ showList: false })}</div>
        </div>
      </div>
    </>
  );
}

export default FooterComponent;
