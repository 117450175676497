import "./flockerGalleryPage.scss";
import { useEffect, useState } from "react";
import ImageComponent from "../sharedComponent/ImageComponent";
import { Web3Auth } from "@web3auth/web3auth";
import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from "@web3auth/base";
import RPC from "./web3RPC";
import FlockerCard from "./components/FlockerCard"

const chainId = "0x89"; // Polygon Mainnet
const clientId = "BOOtznaE1iLGYWlMDey0Qb2I-NwIomOY5lwLHnGZRDq1zEsiyZKh70PNvHY4vSBbrh-HDNRmrGso9gJneUaBqSc";

function FlockerGalleryPage() {
  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3Auth({
          clientId,
          chainConfig: {
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: chainId,
            rpcTarget: "https://polygon-mainnet.g.alchemy.com/v2/Qlm9ZgpgZjAnAOfGm981khNoR3LZLREd",
          },
          uiConfig: {
            appLogo: "https://parrotly.finance/asset/token_icon.svg",
          },
        });


        await web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "openlogin",
              showOnModal: false,
            },
          }
        });

        setWeb3auth(web3auth);
      } catch (error) {
        setMessage(error.message);
      }
    };

    init();
  }, []);

  const getFlockers = async (web3authProvider) => {
    // Get address
    const rpc = new RPC(web3authProvider);
    const address = await rpc.getAccounts();

    // Get jwt
    const authentication = await web3auth.authenticateUser();

    // Post info to api
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authentication.idToken,
    };

    try {
      const res = await fetch("https://api.parrotly.finance/proto/v0/nvs", {
        method: "POST",
        headers: headers,
        body: `public_address=${address}`,
      });

      const json = await res.json();
      if (!json.status || typeof (json.status) !== "string") {
        throw new Error("Invalid status");
      }

      if (json.status !== "verified") {
        setMessage(json.status);
      }
      else {
        setNfts(json.nfts);
      }
    }
    catch (error) {
      setMessage("Error contacting nvs");
    }
  }

  const connect = async () => {
    const web3authProvider = await web3auth.connect();

    if (!provider) {
      setProvider(web3authProvider);
    }

    await getFlockers(web3authProvider);
  };

  const disconnect = async () => {
    await web3auth.logout();
    setProvider(null);
  };

  const connectedView = (
    <>
      
        {nfts ?
          <div className="fsc-row-container flex-container align-spaced">
            {nfts.map((nft, i) =>
              <FlockerCard key={i} nft={nft} />
            )} 
          </div> :
          <div>Loading your flockers!</div>
        }
      <button onClick={disconnect} className={buttonClass()}>
        Disconnect
      </button>
    </>
  );

  const disconnectedView = (
    <>
      <div className="fsc-logo">
        <ImageComponent
          className=""
          url="/asset/fsc_logo.png"
          altText="Flockers Social Club Logo"
        />
      </div>
      <button disabled={web3auth ? "" : "disabled"} onClick={connect} className={buttonClass()}>
        Connect
      </button>
    </>
  );

  function buttonClass() {
    return "hero-btn button large rounded outline purple web3auth-connect";
  }

  return (
    <>
      <section className="text-center">
        <h2 className="title">Flocker Gallery</h2>
        {provider ? connectedView : disconnectedView}
        <div>{message}</div>
      </section>
    </>
  );
}
export default FlockerGalleryPage;
