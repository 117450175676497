import "./Fsc.scss";
import ImageComponent from "../sharedComponent/ImageComponent";
import { partnersUrl } from "../const";
import NftBuyBox from "./NftBuyBox";

function FscPage() {
  return (
    <>
      <section className="hero border row align-justify align-middle">
        <div className="border columns small-12 medium-12 large-6 small-collapse">
          <h1>
            Flockers
            <span className="thin"> Social Club</span>
          </h1>
          <p>
            An exclusive club for Parrotly Finance. Members of the social club have perks with businesses that have
            partnered with Parrotly Finance Inc. As the company develops its products and services; club members are
            also given a first look and would have access to special features.
          </p>
          <NftBuyBox></NftBuyBox>
        </div>
        <div className="border columns small-12 medium-12 large-6 small-collapse text-center">
          <ImageComponent
            className="wallet-hero-image fsc-preview"
            url="/asset/FSC-preview.png"
            altText="FSC-preview"
          />
        </div>
      </section>

      <section>
        <h2 className="text-center medium-text-left">Perks and Benefit</h2>
        <hr style={{ margin: "3rem 0" }} />
        <div className="benefit-row-container row small-collapse">
          <div className="border columns small-12 medium-5 small-collapse image-container text-center">
            <ImageComponent className="benefit-logo" url="/asset/Kapricat.png" altText="Kapricat Kreations" />
          </div>
          <div className="columns small-12 medium-7 small-collapse flex-container align-center-middle">
            <div className="contents ">
              <h3 className="title">Kaprikat Kreations</h3>
              <p>Handcrafted custom mini sculptures that can bring metaspace into the real world.</p>

              <ul className="blue-bullet text-uppercase">
                <li>FSC PERK: 10% OFF</li>
              </ul>

              <a className="text-uppercase site-link" href={partnersUrl.kapricat}>
                visit website <i className="arrow right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="benefit-row-container row small-collapse">
          <div className="columns small-12 medium-7 small-collapse flex-container align-center-middle">
            <div className="contents ">
              <h3 className="title">Room 101</h3>
              <p>
                High-end line of custom collections. Room 101 Brand has been featured in top lifestyle, fashion, and
                specialty magazines worldwide as well as making several appearances on major network television.
              </p>

              <ul className="blue-bullet text-uppercase">
                <li>fsc perk: 20% off</li>
              </ul>

              <a className="text-uppercase site-link" href={partnersUrl.room101}>
                visit website <i className="arrow right"></i>
              </a>
            </div>
          </div>
          <div className="border columns small-12 medium-5 small-collapse image-container text-center">
            <ImageComponent className="benefit-logo" url="/asset/Rom101-logo.png" altText="Rom101 logo" />
          </div>
        </div>
      </section>
    </>
  );
}
export default FscPage;
