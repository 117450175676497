import { useState } from "react";
import { NavLink } from "react-router-dom";
import { routePath } from "../../const";
import Logo from "../logo/logo";
import styles from "./MobileHeader.module.scss";
function MobileHeaderComponent() {
  const [showMenu, setShowMenu] = useState();

  return (
    <>
      <div
        className={`${styles.mobile_header} flex-container align-top align-justify hide-for-large`}
        data-testid="mobile-menu-container"
      >
        <div className="left-column">
          <Logo />
        </div>

        <button className="material-icons" type="button" data-testid="menuButton" onClick={() => setShowMenu(true)}>
          <span>menu</span>
        </button>
      </div>
      {showMenu && (
        <div className={styles.overlay_container} data-testid="menuBody">
          <div className={`${styles.mobile_header} flex-container align-top align-justify hide-for-large`}>
            <div className={styles.gray}>
              <Logo />
            </div>

            <button className="material-icons" type="button" onClick={() => setShowMenu(false)}>
              <span>close</span>
            </button>
          </div>
          <nav className="text-center">
            <NavLink
              title="Home"
              data-testid="mobile-menu-container-link"
              activeclassname={styles.is_active}
              to={routePath.home}
              onClick={() => setShowMenu(false)}
            >
              home
            </NavLink>
            <NavLink title="FLOCKERS SOCIAL CLUB" to={routePath.fsc} onClick={() => setShowMenu(false)}>
              flockers social club
            </NavLink>
            <NavLink title="ABOUT" to={routePath.about} onClick={() => setShowMenu(false)}>
              about
            </NavLink>
            <a
              title="buy pbirb"
              href="https://quickswap.exchange/#/swap?outputCurrency=0x60eec374a1ba3907e9bdd8a74ce368d041d89c79"
              target="_blank"
              rel="noreferrer"
            >
              buy PBIRB
            </a>
            <a
              title="documentation"
              href="https://docs.parrotly.finance/"
              target="_blank"
              rel="noreferrer"
              onClick={() => setShowMenu(false)}
            >
              documentation
            </a>
          </nav>
        </div>
      )}
    </>
  );
}

export default MobileHeaderComponent;
