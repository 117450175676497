function ComingSoon() {
  return (
    <>
      <section className="hero border row align-justify align-middle">
        <div className="border columns small-12 medium-6 small-collapse">
          <h1>
            <span className="thin">Coming</span> Soon...
          </h1>
        </div>
        <div className="border columns small-12 medium-6 small-collapse">
          <img src="/asset/hero.svg" alt="Parrotly logo" />
        </div>
      </section>
      <hr className="wallet" />
    </>
  );
}
export default ComingSoon;
