import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AboutPage from "./About/About";
import ComingSoonPage from "./comingSoonPage/comingSoonPage";
import DashboardPage from "./dashboardPage/DashboardPage";
import { routePath } from "./const";
import FscPage from "./FSC/FscPage";
import IcoPage from "./IcoPage/IcoPage";
import Homepage from "./homePage/homePage";
import FlockerGalleryPage from "./flockerGalleryPage/flockerGalleryPage";
import FooterComponent from "./partial/footer/footerComponent";
// import AggregatorPage from "./aggregatorPage/AggregatorPage";

import HeaderComponent from "./partial/header/headerComponent";
function App() {
  return (
    <div className="root-container" data-testid="root-container">
      <Router>
        <div>
          <HeaderComponent />
          <ul className="is-hidden">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          </ul>
        </div>

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <main className="border">
          <Routes>
            <Route exact path={routePath.home} element={<Homepage />}></Route>
            <Route exact path={routePath.fsc} element={<FscPage />}></Route>
            <Route exact path={routePath.about} element={<AboutPage />}></Route>
            <Route exact path={routePath.ico} element={<IcoPage />}></Route>
            <Route exact path={routePath.dashboard} element={<DashboardPage />}></Route>
            <Route exact path={routePath.flockerGallery} element={<FlockerGalleryPage />}></Route>
            {/* <Route exact path={routePath.aggregator} element={<AggregatorPage />}></Route> */}
            <Route path="*" element={<ComingSoonPage />} />
          </Routes>
        </main>
        <FooterComponent />
      </Router>
    </div>
  );
}

export default App;
