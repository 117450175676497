import ImageComponent from "../sharedComponent/ImageComponent";
import styles from "./EquilbirbiumLiquidityBox.module.scss";

function EquilbirbiumLiquidityBox() {
    return (
        <>
            <div className={`${styles.card} row align-center-middle`}>
                <div className={`medium-4 align-right flex-container ${styles["equilbirbium-liquidity-container"]}`}>
                    <ImageComponent
                        altText="Learn more about equilbirbium liquidity"
                        className={`${styles["equilbirbium-liquidity"]}`}
                        url="/asset/equilbirbium-liquidity.svg"
                    />
                </div>
                <div className="medium-8">
                    <h2 className="title">Equilbirbium Liquidity</h2>
                    <p>
                        PBirb, our native token, combines deflationary mechanisms and the benefits of utilizing USD+ for a liquidity pool to create a new type of transactional token
                    </p>
                    <p>
                        <a className={`${styles["learn-more"]}`} rel="noreferrer" target="_blank" href="https://docs.parrotly.finance/pbirb/equilbirbium-liquidity">
                            Learn More <span className={`${styles["learn-more-arrow"]} material-icons`}>arrow_right_alt</span>
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default EquilbirbiumLiquidityBox;
