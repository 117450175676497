import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { DashboardUtils } from "../service/Dashboard.service";
import { on } from "../service/event.service";
import { off } from "process";
import WatchAssetButton from "../sharedComponent/WatchAssetButton/WatchAssetButton";
import ConnectWalletButton from "../sharedComponent/ConnectWalletButton";
// import DisconnectWalletButton from "../sharedComponent/DisconnectWalletButton";

function DashboardPage() {
  useEffect(() => {
    on("connect", handleConnectWalletEvent);
    
    return () => {
      off("connect", handleConnectWalletEvent);
    };
  }, []);

  const [walletAddress, setWalletAddress] = useState();
  // const [walletProvider, setWalletProvider] = useState(null);

  const handleConnectWalletEvent = async ({ detail }) => {
    const { status } = detail;
    if (status === "success") {      
      setWalletAddress(detail.walletAddress);

      const walletData = await DashboardUtils.getWalletData(detail.walletAddress);

      var addStart = walletData.address.substring(0, 4);
      var addEnd = walletData.address.slice(walletData.address.length - 4);
      var parsedHoldingsUSD = DashboardUtils.parseNumberToUnit(walletData.USDval);
      var parsedHoldings = DashboardUtils.parseNumberToUnit(walletData.balance);
  
      document.getElementById("holding-usd").innerHTML = `<h3 class="small">$</h3><h3 class="bigger">${parsedHoldingsUSD[0]}</h3><h3 class="small">.${parsedHoldingsUSD[1]}</h3>`;
      document.getElementById("holding-count").innerHTML = `${parsedHoldings[0]}.${parsedHoldings[1]}`;
      document.getElementById("connect-container").innerHTML = `
      <h3 className="text-left dashboard-info-card-header big uppercase">${addStart}...${addEnd}</h3>
      <!-- <p>Disconnect</p> -->
      `;
      // setErrorMsg(undefined);
    } else {
      setWalletAddress(undefined);
      // setErrorMsg(detail.message);
    }
  };

  async function loadDashboard() {
    try {
      let dashboardData = await DashboardUtils.getDashboardData();
      // console.log(dashboardData);

      let parsedMcap = DashboardUtils.parseNumberToUnit(dashboardData.mcap);
      document.getElementById("mcap").innerHTML = `<h3 class="small">$</h3><h3 class="big">${parsedMcap[0]}</h3><h3 class="small">.${parsedMcap[1]}</h3>`;

      let parsedPrice = DashboardUtils.parseNumberToUnit(dashboardData.price);
      document.getElementById("price").innerHTML = `<h3 class="small">$</h3><h3 class="big">${parsedPrice[0]}</h3><h3 class="small">.${parsedPrice[1]}</h3>`;

      let parsedBurned = DashboardUtils.parseNumberToUnit(dashboardData.burnt);
      document.getElementById("burnt").innerHTML = `<h3 class="big">${parsedBurned[0]}</h3><h3 class="small">.${parsedBurned[1]}</h3>`;

      let parsedCircSupply = DashboardUtils.parseNumberToUnit(dashboardData.circSupply);
      document.getElementById("circ").innerHTML = `<h3 class="big">${parsedCircSupply[0]}</h3><h3 class="small">.${parsedCircSupply[1]}</h3>`;
    } catch (error) 
    {
      console.log(error);
      //loadDashboard();
    }
  }

  loadDashboard();

  return (
    <>
      <section className="hero border row align-justify">
        <div className="border columns dashboard-info-container small-12 medium-6 small-collapse align-right">
          <div className="holding-container">
            <p className="text-left dashboard-info-card-header uppercase">holdings</p>

            <div className="dashboard-info-container align-left" id="holding-usd">
              <h3 className="big">$----------</h3>
            </div>

            <h3 className="text-left small thin">
              <img src="/asset/token_icon.svg" className="pbirb-balance-logo" alt="pbirb balance logo"></img>&nbsp;
              <span id="holding-count">----------</span>&nbsp;pBirb
            </h3>
          </div>
        </div>
        <div className="border columns small-12 medium-6 small-collapse justify-left">
          <div id="connect-container">
          <ConnectWalletButton status={!walletAddress ? "disconnected" : "connected"}></ConnectWalletButton>
            {walletAddress &&
              <span className="wallet-address">
                Connected with wallet: <span className="hash-address">{walletAddress}</span>
              </span>
            }
          </div>

          <div>
            <WatchAssetButton></WatchAssetButton>
          </div> 

          <a
            href="https://quickswap.exchange/#/swap?outputCurrency=0x60eec374a1ba3907e9bdd8a74ce368d041d89c79"
            target="_blank"
            rel="noreferrer"
          >
            <button type="button" id="swap" className="hero-btn button rounded dashboard outline uppercase w-100">
              swap
            </button>
          </a>
        </div>
      </section>

      <section>
        <div className="row small-collapse">
          <div className="columns small-12 medium-6 large-3 small-collapse flex-container" style={{ padding: "2rem" }}>
            <div className="dashboard-info-card">
              <p className="dashboard-info-card-header uppercase">market cap</p>
              <div className="dashboard-info-container" id="mcap"></div>
            </div>
          </div>
          <div className="columns small-12 medium-6 large-3 small-collapse flex-container" style={{ padding: "2rem" }}>
            <div className="dashboard-info-card">
              <p className="dashboard-info-card-header uppercase">price</p>
              <div className="dashboard-info-container" id="price"></div>
            </div>
          </div>
          <div className="columns small-12 medium-6 large-3 small-collapse flex-container" style={{ padding: "2rem" }}>
            <div className="dashboard-info-card">
              <p className="dashboard-info-card-header uppercase">burnt</p>
              <div className="dashboard-info-container" id="burnt"></div>
            </div>
          </div>
          <div className="columns small-12 medium-6 large-3 small-collapse flex-container" style={{ padding: "2rem" }}>
            <div className="dashboard-info-card">
              <p className="dashboard-info-card-header uppercase">circ supply</p>
              <div className="dashboard-info-container" id="circ"></div>
            </div>
          </div>
        </div>

        <hr style={{ margin: "3rem 0" }} />
      </section>
    </>
  );
}
export default DashboardPage;
