import { metaMaskService } from "../../service/MetaMask.service";
import { walletConnectService } from "../../service/WalletConnect.service";
import Modal from "../Modal/Modal";
import WalletOption from "./WalletOption/WalletOption";
import "./WalletSelector.scss";

function WalletSelector(props) {
  async function metaMaskConnect() {
    metaMaskService.init();
    metaMaskService.connect();
    props.onClose();
  }

  async function walletConnectConnect() {
    walletConnectService.init();
    walletConnectService.connect();
    props.onClose();
  }

  return <>
    <Modal show={props.show} title="Connect a wallet" onClose={props.onClose}>
      <WalletOption text="MetaMask" onClick={metaMaskConnect}></WalletOption>
      <WalletOption text="WalletConnect" onClick={walletConnectConnect}></WalletOption>
    </Modal>
  </>
}

export default WalletSelector;
