import "./homePage.scss";
import ImageComponent from "../sharedComponent/ImageComponent";
import EquilbirbiumLiquidityBox from "./EquilbirbiumLiquidityBox";

function Homepage() {
  return (
    <>
      <section className="hero border row align-justify align-middle">
        <div className="border columns small-12 medium-7 small-collapse">
          <h1 className="embracing-change-header">
            <span className="thin">Embracing</span> Change
          </h1>
          <p className="embracing-change-text"> Developing the future of finance with the combined benefits of centralized and decentralized finance.</p>
          <a href="https://discord.gg/parrotly" _target="_blank" className="hero-btn button outline purple">
            JOIN OUR COMMUNITY
          </a>
          <a href="/dashboard" className="enter-dapp-link">
            ENTER dApp <span className="enter-dapp-arrow material-icons">arrow_right_alt</span>
          </a>
        </div>
        <div className="border columns small-12 medium-5 small-collapse text-center large-text-left">
          <img src="/asset/hero.svg" alt="Parrotly Finance" className="home-hero-image" />
        </div>
      </section>

      <section className="equilbirbium-intro">
        <div className="row align-spaced cards-container">
          <EquilbirbiumLiquidityBox/>
        </div>
      </section>

      <section className="align-spaced wallet-containers text-center">
        <h2>Flock Wallet</h2>
        <p className="subtitle">The first product in development is unlike current DeFi or custodial wallets.</p>
        <div className="wallet-hero">
          <ImageComponent
            className="wallet-hero-image show-for-medium"
            url="/asset/flockwallet-v3.svg"
            altText="flock wallet"
          />
          <ImageComponent
            className="wallet-hero-image-mobile first hide-for-medium"
            url="/asset/Rectangle.png"
            altText="flock wallet"
          />
          <ImageComponent
            className="wallet-hero-image-mobile hide-for-medium"
            url="/asset/flock-wallet-sub.png"
            altText="flock wallet"
          />
        </div>
      </section>
    </>
  );
}
export default Homepage;
