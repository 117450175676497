import { Link } from "react-router-dom";
import { routePath } from "../../const";
import Logo from "../logo/logo";
import MobileHeader from "../MobileNav/MobileHeader";

function HeaderComponent() {
  return (
    <>
      <div className="site-header flex-container align-justify show-for-large">
        <div className="left-column columns">
          <Logo />
        </div>

        <nav className="right-column flex-container align-justify align-middle ">
          <div className="flex-container align-center main-nav">
            <Link title="Home" to={routePath.home}>
              Home
            </Link>
            <Link title="FLOCKERS SOCIAL CLUB" to={routePath.fsc}>
              FLOCKERS SOCIAL CLUB
            </Link>
            <Link title="ABOUT" to={routePath.about}>
              ABOUT
            </Link>
          </div>
          <div className="flex-container align-center secondary">
            <a
              href="https://docs.parrotly.finance/"
              target="_blank"
              className="hero-btn button rounded white outline docs-button"
              rel="noreferrer"
            >
              <span className="material-icons">article</span>
              <span>&nbsp;DOCS</span>
            </a>
          </div>
        </nav>
      </div>
      <MobileHeader />
    </>
  );
}

export default HeaderComponent;
