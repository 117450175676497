import "./WalletOption.scss";

function WalletOption(props) {
  return <>
    <div className="wallet-option flex-container">
      <button className="button" onClick={props.onClick}>{props.text}</button>
    </div>
  </>
}

export default WalletOption;
