import { useEffect } from "react";
import "./Modal.scss";

function Modal(props) {
  const handleEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  }

  useEffect(() => {
    document.body.addEventListener("keydown", handleEscapeKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleEscapeKeyDown);
    }
  });

  return (
    <div className={`modal ${props.show ? "show" : ""}`} onClick={props.onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" aria-label="Close alert" type="button" onClick={props.onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-header">
          <h4 className="modal-title">{props.title}</h4>
        </div>
        <div className="modal-body">{props.children}</div>
      </div>
    </div>
  )
}

export default Modal;
