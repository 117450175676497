import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { fscParameters } from "../const";

class Dashboard {
  web3;
  walletAddress;
  constructor() {
    this.web3 = createAlchemyWeb3(fscParameters.alchemyId);
  }

  tokens = {
    USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    pBIRB: "0x60EeC374a1Ba3907e9BdD8a74cE368D041d89C79",
    USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  };

  ABI = [
    {
      constant: true,
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ];

  parseNumberToUnit(number) {
    var parsedInt;
    if (number === 0) {
      parsedInt = ["0", "00"];
    }
    else if (number < 1 && number > 0) {
      let parts = number.toFixed(7).split('.')
      parsedInt = [parts[0], parts[1]];
    }
    else {
      var splitNumber = number.toString().split('.');
      let unitsOfTen = splitNumber[0].length;

      var unit = '';
      var reduceBy = 0;

      if (unitsOfTen > 9) {
        unit = 'B';
        reduceBy = 9;

      }
      else if (unitsOfTen > 6) {
        unit = 'M';
        reduceBy = 6;
      }
      else if (unitsOfTen > 3) {
        unit = 'K';
        reduceBy = 3;
      }

      let x = Math.pow(10, reduceBy);
      let y = number / x;
      let z = y.toFixed(2);

      let parts = z.split('.');

      parsedInt = [parts[0], parts[1] + unit];
    }
    return parsedInt;
  }

  async getWalletData(walletAddress) {
    try {
      let walletBalance = await this.getTokenBalance(this.tokens["pBIRB"], walletAddress);

      let pBIRBprice = await this.getPBIRBprice();

      let walletUSD = walletBalance * pBIRBprice;

      let walletData = {
        balance: walletBalance,
        USDval: walletUSD,
        address: walletAddress,
      };

      return walletData;
    } catch (error) {
      console.log(error);
    }
  }

  async getTokenBalance(token, walletAddress) {
    let contract = new this.web3.eth.Contract(this.ABI, token);

    let b = await contract.methods.balanceOf(walletAddress).call();

    if (token === this.tokens["USDC"] || token === this.tokens["USDT"]) {
      return b / 1e6;
    } else {
      return b / 1e18;
    }
  }

  async getPBIRBprice() {
    try {
      let WMATICinLP = await this.getTokenBalance(this.tokens["WMATIC"], "0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827");

      let USDCinLP = await this.getTokenBalance(this.tokens["USDC"], "0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827");

      let mp = USDCinLP / WMATICinLP;
      let MATICprice = mp.toFixed(2);

      let pBIRBbalance = await this.getTokenBalance(this.tokens["pBIRB"], "0x1316e0655428840Dba61Bd75980cBE43488C35fd");

      let WMATICbalance = await this.getTokenBalance(this.tokens["WMATIC"], "0x1316e0655428840Dba61Bd75980cBE43488C35fd");

      let x = WMATICbalance / pBIRBbalance;
      let y = x * MATICprice;

      let pBIRBprice = y;

      return pBIRBprice;
    } catch (error) {
      console.error(error);
    }
  }

  async getDashboardData() {
    try {
      let deadWalletBalance = await this.getDeadWalletBalance();

      let circSupply = 1000000000000 - deadWalletBalance;

      let pBIRBprice = await this.getPBIRBprice();

      let mcap = circSupply * pBIRBprice;

      let dashboardData = {
        burnt: deadWalletBalance,
        circSupply: circSupply,
        price: pBIRBprice,
        mcap: mcap,
      };

      return dashboardData;
    } catch (error) {
      console.error(error);
    }
  }

  async getDeadWalletBalance() {
    try {
      let deadWalletBalance = await this.getTokenBalance(this.tokens["pBIRB"], "0x000000000000000000000000000000000000dEaD");

      return deadWalletBalance;
    } catch (error) {
      console.error(error);
    }
  }
}
export const DashboardUtils = new Dashboard();
