import "./About.scss";
import ImageComponent from "../sharedComponent/ImageComponent";
import TeamInfo from "./teamInfo";

function AboutPage() {
  return (
    <>
      <section className="hero border row align-justify align-middle">
        <div className="border columns small-12 large-5 small-collapse">
          <section className="hero-section">
            <h1>
              <span className="thin">Our</span> Mission
            </h1>
            <p>
              To fill the void and bridge the gap between decentralized crypto usage and the centralization needed for
              large businesses and corporations. Additionally, Parrotly strives to reduce the learning curve needed to
              be able to partake in the crypto space to allow the mass to embrace the change.
            </p>
          </section>
          <section>
            <h1>
              <span className="thin">Our</span> Vision
            </h1>
            <p>
              Not having to choose between centralization and decentralization, but to have a hybrid of both that will
              pave the path to the future of finance.
            </p>
          </section>
        </div>
        <div className="border columns small-12 medium-12 large-6 small-collapse text-center">
          <ImageComponent className="wallet-hero-image" url="/asset/about-hero.png" altText="about us" />
        </div>
      </section>

      <section>
        <h2>Meet the Team</h2>
        <hr style={{ margin: "3rem 0" }} />

        <TeamInfo></TeamInfo>
      </section>
    </>
  );
}
export default AboutPage;
