import ImageComponent from "../ImageComponent";
import "./WatchAssetButton.scss";

function WatchAssetButton() {
  const watchAsset = async () => {
    const tokenAddress = "0x60eec374a1ba3907e9bdd8a74ce368d041d89c79";
    const tokenSymbol = "PBIRB";
    const tokenDecimals = 18;
    const tokenImage = "https://parrotly.finance/asset/pbirb_token_icon.png";
    
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    
      if (wasAdded) {
        console.log("$PBIRB token added");
      } else {
        console.log("Unable to add $PBIRB token");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return <>
    <button type="button" className="hero-btn button rounded dashboard outline uppercase w-100" onClick={watchAsset}>
      <ImageComponent
        className="metamask-logo"
        url="/asset/metamask.svg"
        altText="MetaMask"
      />
      Add $PBIRB
    </button>
  </>
}

export default WatchAssetButton;
