import { useState } from "react";
import ImageComponent from "../sharedComponent/ImageComponent";
import {
  JoshuaDetail,
  // EzekielDetail,
  MatthewDetail,
  FranzDetail,
  DennisDetail,
  ZhouDetail,
  MindyDetail,
} from "./detail";
import PopUp from "./PopUp";

function TeamInfo() {
  const teamInfos = [
    {
      name: 'Joshua Hood "Cyril Keir"',
      title: "Co-Founder | CEO",
      image: "Joshua.png",
      linkedIn: "https://www.linkedin.com/in/joshua-hood-619854220/",
      detail: <JoshuaDetail></JoshuaDetail>,
    },

    // {
    //   name: "Ezekiel Racelis",
    //   title: "Co-Founder | CoS",
    //   image: "Ezekiel Racelis.png",
    //   linkedIn: "https://www.linkedin.com/in/ezekiel-racelis-85288a215/",
    //   detail: <EzekielDetail></EzekielDetail>,
    // },
    {
      name: "Matthew Merrill",
      title: "Co-Founder | CTO",
      image: "Matthew Merrill.png",
      detail: MatthewDetail,
    },
    {
      name: "Mindy McClusky",
      title: "Marketing Coordinator",
      image: "Mindy McClusky.png",
      linkedIn: "https://www.linkedin.com/in/mandolynn-mcclusky/",
      detail: MindyDetail,
    },
    {
      name: "Zhou Fang",
      title: "Senior Developer",
      image: "zhou.png",
      linkedIn: "https://www.linkedin.com/in/zhou-fang-671a23b9/",
      detail: ZhouDetail,
    },
    {
      name: "Franz Escolastico",
      title: "UI/UX Designer",
      image: "Franz.png",
      linkedIn: "https://www.linkedin.com/in/franzesco/",
      detail: FranzDetail,
    },
    {
      name: "Dennis Perrin",
      title: "NFT Consultant",
      image: "Dennis Perrin.png",
      linkedIn: "",
      detail: DennisDetail,
    },
  ];
  const [PopUpDetail, setPopUpDetail] = useState();
  const teamCards = teamInfos.map(({ name, title, image, linkedIn, detail }) => {
    const imageUrl = `/asset/team/${image}`;
    return (
      <div className="team-card" key={name}>
        <div className="image-container">
          <ImageComponent
            className="team-Image"
            url={imageUrl}
            altText={name}
            handleClick={() => {
              setPopUpDetail(detail);
            }}
          />
        </div>
        <div>
          <div className="name">{name}</div>
          <div className="thin title">{title}</div>
          {linkedIn && (
            <a href={linkedIn} target="_blank" rel="noreferrer">
              <ImageComponent className="social-icon" url="/asset/social-icon/LinkedIn.png" altText="linkedIn" />
            </a>
          )}
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="team-row-container flex-container align-spaced">{teamCards}</div>
      {PopUpDetail && <PopUp onClose={() => setPopUpDetail(null)} PopUpDetail={PopUpDetail}></PopUp>}
    </>
  );
}
export default TeamInfo;
